import type { FC } from "react";

import { getUserLabel } from "@relatable/helpers/user";
import { type AddFields, Table, type TableColumn } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { Menu } from "components/ui/Menu";
import { useUpdateExpenseMutation } from "modules/payments/generated";

import {
  CampaignExpensesDocument,
  type CampaignExpensesQuery,
  useDeleteExpenseMutation
} from "./generated";

export const ExpenseTable: FC<{
  loading: boolean;
  expenses: CampaignExpensesQuery["campaign_expenses"];
}> = ({ loading, expenses }) => {
  const [update] = useUpdateExpenseMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignExpensesDocument]
  });
  const [deleteExpense] = useDeleteExpenseMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignExpensesDocument]
  });
  const rows =
    expenses?.map(e => {
      const r = {
        key: e.id,
        expenseId: e.id,
        description: e.description,
        userLabel: getUserLabel(e.campaign_user?.user),
        userId: e.campaign_user?.user?.id,
        amount: e.amount,
        vat: e.vat,
        currency: e.campaign_user?.currency,
        paymentState: e.payment_state,
        campaignUser: e.campaign_user,
        state: e.campaign_user?.state,
        campaignStub: e.campaign_user?.campaign.stub,
        receipt: e.receipt
      };
      return r as AddFields<typeof r, "actions">;
    }) ?? [];

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "description",
      headerName: "Description"
    },

    {
      field: "userLabel",
      headerName: "Pay participant",
      renderCell: ({ row, value }) => <Link to={`/user/${row.userId}`}>{value}</Link>
    },
    {
      field: "amount",
      headerName: "Amount (excl. VAT)"
    },
    { field: "vat", headerName: "VAT" },
    {
      field: "currency",
      headerName: "Currency"
    },
    {
      field: "paymentState",
      minWidth: 170,
      headerName: "Payment status",
      renderCell: ({ value, row }) => {
        if (!row.state) return "confirm participant first";
        switch (value) {
          case "CUSTOM_PAYMENT":
            return "To be paid manually";
          case "CUSTOM_PAYMENT_PAID":
            return "Paid manually";
          default:
            throw Error(`${value} is not handled.`);
        }
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Menu
          options={[
            {
              label: "Edit",
              href: `edit/${row.key}`,
              divider: true
            },
            {
              label: "Set manual payment",
              variant: row.paymentState === "CUSTOM_PAYMENT" ? "disabled" : undefined,
              onClick: () =>
                update({
                  variables: {
                    id: row.expenseId,
                    set: { payment_state: "CUSTOM_PAYMENT" }
                  }
                })
            },
            {
              label: "Set manual payment paid",
              variant: row.paymentState === "CUSTOM_PAYMENT_PAID" ? "disabled" : undefined,
              divider: true,
              onClick: () =>
                update({
                  variables: {
                    id: row.expenseId,
                    set: { payment_state: "CUSTOM_PAYMENT_PAID" }
                  }
                })
            },
            {
              label: "Show receipt",
              variant: row.receipt ? undefined : "disabled",
              onClick: () => row.receipt && window.open(row.receipt, "_blank")?.focus()
            },
            {
              label: "Delete",
              variant: "danger",
              onClick: () => deleteExpense({ variables: { id: row.expenseId } })
            }
          ]}
        />
      )
    }
  ];
  return (
    <Table
      tableId="campaign-expense"
      canExportCSV
      sortable
      loading={loading}
      columns={columns}
      rows={rows}
    />
  );
};
