import { NavigateWithParams } from "@relatable/ui/NavigateWithParams";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { NotFound } from "components/NotFound";
import { Redirect } from "components/Redirect";
import { Campaign } from "modules/campaign";
import { Archive as CampaignArchive } from "modules/campaign/Archive/Archive";
import { ClientManager as CampaignClientManager } from "modules/campaign/ClientManager/ClientManager";
import { Content as CampaignContent } from "modules/campaign/Content";
import { ContentApproval as CampaignContentApproval } from "modules/campaign/ContentApproval";
import { Expenses as CampaignExpenses } from "modules/campaign/Expenses";
import { CampaignList } from "modules/campaign/List";
import { Messages as CampaignMessages } from "modules/campaign/Messages";
import { Overview as CampaignOverview } from "modules/campaign/Overview";
import { Participants as CampaignParticipants } from "modules/campaign/Participants";
import { CampaignPosts } from "modules/campaign/Posts";
import { Snaps as CampaignSnaps } from "modules/campaign/Snaps";
import { Stories as CampaignStories } from "modules/campaign/Stories";
import { TikToks as CampaignTikToks } from "modules/campaign/TikToks";
import { Timeline as CampaignTimeline } from "modules/campaign/Timeline";
import { Videos as CampaignVideos } from "modules/campaign/Videos";
import { Chat } from "modules/chat";
import { Curation } from "modules/curation";
import { Finance } from "modules/finance";
import { Hypeauditor } from "modules/hypeAuditor";
import { Links } from "modules/links";
import { Overview } from "modules/overview";
import { Payments } from "modules/payments";
import { CampaignUpdate } from "modules/project/CampaignUpdate";
import { ProjectCreate } from "modules/project/ProjectCreate";
import { ProjectUpdate } from "modules/project/ProjectUpdate";
import { TimeReporting } from "modules/timeReporting";
import { User } from "modules/user";

import { Layout } from "./Layout";
import { Stats } from "./stats";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      {["/", "/overview/*"].map(path => (
        <Route key={path} path={path} element={<Overview />} />
      ))}

      <Route path="/admin/*" element={<Redirect to={p => p.replace("/admin/", "/")} />} />

      <Route path="chat/*" element={<Chat />} />
      <Route path="links/*" element={<Links />} />
      <Route path="time-reporting/*" element={<TimeReporting />} />

      <Route path="/finance/*" element={<Finance />} />
      <Route path="/curation/*" element={<Curation />} />
      <Route path="/user/:userId/*" element={<User />} />
      <Route path="/payments" element={<Payments />} />

      <Route path="hypeauditor/*" element={<Hypeauditor />} />

      <Route path="project/*">
        <Route path="new" element={<ProjectCreate />} />
        <Route path=":projectStub/edit" element={<ProjectUpdate />} />

        <Route
          path=":projectStub/campaign/:campaignStub/edit"
          element={<Redirect to={p => `/campaign/${p.split("/campaign/")[1]}`} />}
        />
        {/* Legacy redirect */}
        <Route
          path=":projectStub/campaign/:campaignStub/setup"
          element={<NavigateWithParams to=":projectStub/campaign/:campaignStub/edit" replace />}
        />
      </Route>

      <Route path="campaigns" element={<Campaign />}>
        <Route path="list/*" element={<CampaignList />} />
        <Route path="" element={<Redirect to={p => `${p}/list`} />} />
      </Route>

      <Route path="campaign/:campaignStub" element={<Campaign />}>
        <Route path="edit/*" element={<CampaignUpdate />} />
        <Route path="overview/*" element={<CampaignOverview />} />
        <Route path="timeline/*" element={<CampaignTimeline />} />
        <Route path="manage/*" element={<CampaignClientManager />} />
        <Route path="approval/*" element={<CampaignContentApproval />} />
        <Route path="content/*" element={<CampaignContent />} />
        <Route path="posts/*" element={<CampaignPosts />} />
        <Route path="stories/*" element={<CampaignStories />} />
        <Route path="tiktoks/*" element={<CampaignTikToks />} />
        <Route path="snaps/*" element={<CampaignSnaps />} />
        <Route path="videos/*" element={<CampaignVideos />} />
        <Route path="participants/*" element={<CampaignParticipants />} />
        <Route path="expenses/*" element={<CampaignExpenses />} />
        <Route path="messages/*" element={<CampaignMessages />} />
        <Route path="archive/*" element={<CampaignArchive />} />
        <Route path="" element={<Redirect to={p => `${p}/participants`} />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="stats/*" element={<Stats />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
