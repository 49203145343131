import { type FC, useEffect } from "react";

import styled from "@emotion/styled";
import type {
  Campaign_Timeline_Insert_Input,
  Campaign_Timeline_Updates
} from "@relatable/gql/generated-base";
import { TIMELINE_GROUP_WEIGHT } from "@relatable/helpers/constants";
import { useDebounceFn } from "@relatable/ui/hooks/useDebounceFn";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { TimelineEditor } from "./TimelineEditor";
import { TimelineProgress } from "./TimelineProgress";
import { DEFAULT_TIMELINE } from "./constants";
import {
  TimelineDocument,
  type TimelineQuery,
  useDeleteTimelineRowsMutation,
  useInsertTimelineRowsMutation,
  useTimelineLazyQuery,
  useTimelineQuery,
  useUpdateTimelineRowsMutation
} from "./generated";

export type TimelineItem = TimelineQuery["campaign"][number]["campaign_timeline"][number];

export const Timeline: FC = () => {
  useDocumentTitle("Timeline");

  const { campaignStub } = useParams<{ campaignStub: string }>();
  const { data: timelineData, loading: timelineLoading } = useTimelineQuery({
    variables: { stub: campaignStub ?? "" }
  });

  const [getLatestTimeline, { data: latestTimelineData }] = useTimelineLazyQuery({
    variables: { stub: campaignStub ?? "" },
    returnPartialData: true
  });

  useEffect(() => {
    getLatestTimeline();
  }, [getLatestTimeline]);

  const [insertTimelineRow] = useInsertTimelineRowsMutation();
  const [updateTimelineRows] = useUpdateTimelineRowsMutation();
  const [deleteTimelineRows] = useDeleteTimelineRowsMutation();

  useEffect(() => {
    if (!timelineData?.campaign?.[0]) return;
    if (!timelineData.campaign[0].campaign_timeline.length) {
      insertTimelineRow({
        variables: {
          objects: DEFAULT_TIMELINE.flatMap((group, groupId) =>
            group.map(
              (item, index): Campaign_Timeline_Insert_Input => ({
                campaign_id: timelineData.campaign[0].id,
                group: groupId,
                order: index,
                name: item.name
              })
            )
          )
        },
        refetchQueries: [TimelineDocument]
      }).then(() => getLatestTimeline());
    }
  }, [timelineData, insertTimelineRow, getLatestTimeline]);

  const handleSave = async (newItems: TimelineItem[]) => {
    // need to compare with the latest state instead of the parent state which need to be keept unchanged for the editor
    await getLatestTimeline();
    const timelineItems = latestTimelineData?.campaign[0].campaign_timeline;

    if (!timelineItems) return;

    // the order must be reconstructed
    const groups = TIMELINE_GROUP_WEIGHT.map((_, index) =>
      newItems.filter(item => item.group === index).map((item, i) => ({ ...item, order: i }))
    );

    if (groups.some(group => group.length === 0)) throw new Error("Empty group");

    const normalizedNewItems = groups.flat();

    const toUpdate: Campaign_Timeline_Updates[] = normalizedNewItems
      .filter(item => {
        if (!item.id) return false;
        const oldItem = timelineItems.find(ti => ti.id === item.id);
        if (!oldItem) throw new Error("Cannot find old item");
        if (oldItem.name !== item.name) return true;
        if (oldItem.end_date !== item.end_date) return true;
        if (oldItem.group !== item.group) return true;
        if (oldItem.order !== item.order) return true;
        return false;
      })
      .map(item => ({
        where: { id: { _eq: item.id } },
        _set: {
          name: item.name,
          group: item.group,
          end_date: item.end_date,
          order: item.order
        }
      }));

    const toDelete: number[] = timelineItems
      .filter(item => !normalizedNewItems.find(newItem => newItem.id === item.id))
      .map(item => item.id);

    if (toUpdate.length) {
      await updateTimelineRows({ variables: { updates: toUpdate } });
    }

    if (toDelete.length) {
      await deleteTimelineRows({ variables: { ids: toDelete } });
    }
  };

  const handleAdd = async (groupId: number) => {
    await getLatestTimeline();
    const timelineItems = latestTimelineData?.campaign[0].campaign_timeline;
    if (!timelineItems) throw new Error("Cannot get data");

    const groupItems = timelineItems.filter(item => item.group === groupId);

    const newItem = {
      campaign_id: latestTimelineData.campaign[0].id,
      group: groupId,
      order: groupItems.length,
      name: "Unnamed",
      end_date: null
    };

    const insertRes = await insertTimelineRow({ variables: { objects: [newItem] } });
    const insertedItems = insertRes.data?.insert_campaign_timeline?.returning ?? [];
    if (insertedItems.length !== 1) throw new Error("Insert failed");
    return insertedItems[0];
  };

  const timelineItems = timelineData?.campaign[0].campaign_timeline;

  const [handleChange, { loading: saving }] = useDebounceFn(handleSave, 1000);

  if (timelineLoading) return <Loader />;

  const zeroWeightNames = DEFAULT_TIMELINE.flatMap(t =>
    t.filter(i => i.weight === 0).map(i => i.name)
  );

  return (
    <Root>
      {timelineItems ? (
        <>
          {timelineLoading ? (
            <Loader />
          ) : (
            <TimelineProgress
              items={
                latestTimelineData?.campaign[0].campaign_timeline.filter(
                  i => !zeroWeightNames.includes(i.name)
                ) ?? []
              }
              history={latestTimelineData?.campaign[0].campaign_progress ?? []}
              projectCampaigns={latestTimelineData?.campaign[0]?.project?.campaigns ?? []}
            />
          )}
          <br />
          <TimelineEditor
            campaignId={timelineData?.campaign[0]?.id}
            items={timelineItems}
            saving={saving}
            onChange={handleChange}
            onAdd={handleAdd}
          />
        </>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  font-weight: bold;
`;
