import type { FC } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Divider, Paper, Tooltip, Typography } from "@mui/material";
import { findGreatestElementIndexes, prettifyNumber } from "@relatable/helpers";
import { Table } from "@relatable/ui/Table";

import { ErrorPage } from "components/Error";
import { CircularProgress } from "components/ui/CircularProgress";
import { LinearProgress } from "components/ui/LinearProgress";
import { youtubeCountries } from "lib/youtubeCountries";

import { useCampaignParticipantsStatsQuery } from "./generated";

export const ParticipantsStats: FC<{ campaignStubs: string[] }> = ({ campaignStubs }) => {
  const { data, error } = useCampaignParticipantsStatsQuery({
    variables: { stubs: campaignStubs }
  });

  if (error) {
    return (
      <ErrorPage description="Looks like the campaign you are looking for has been deleted or never existed" />
    );
  }
  const campaign = data?.campaigns[0];

  const users = data?.users_in_lists.reduce(
    (acc, { campaign_user, user_instagram, user_tiktok, user_youtube, hypeauditor }) => {
      const isAcknowledged = ["confirmed", "approved"].includes(campaign_user?.state || "");
      const localPay = campaign_user?.local_invoice_pay || 0;
      acc.all.localPaySum += localPay;
      acc.all.count += 1;
      if (isAcknowledged) {
        acc.acknowledged.localPaySum += localPay;
        acc.acknowledged.count += 1;
      }
      const avgEngagement = hypeauditor?.avg_engagement || 0;
      if (campaign?.platform === "instagram") {
        const followers = user_instagram?.followed_by || 0;

        acc.all.avgEngagementSum += avgEngagement;
        acc.all.followersSum += followers;

        if (isAcknowledged) {
          acc.acknowledged.avgEngagementSum += avgEngagement;
          acc.acknowledged.followersSum += followers;
        }
      }

      if (campaign?.platform === "youtube") {
        acc.all.avgEngagementSum += avgEngagement;
        const followers = user_youtube?.median_views || 0;

        acc.all.followersSum += followers;
        if (isAcknowledged) {
          acc.acknowledged.avgEngagementSum += avgEngagement;
          acc.acknowledged.followersSum += followers;
        }
      }

      if (campaign?.platform === "tiktok") {
        const followers = user_tiktok?.followed_by || 0;

        acc.all.avgEngagementSum += avgEngagement;
        acc.all.followersSum += followers;
        if (isAcknowledged) {
          acc.acknowledged.avgEngagementSum += avgEngagement;
          acc.acknowledged.followersSum += followers;
        }
      }
      return acc;
    },
    {
      all: { followersSum: 0, avgEngagementSum: 0, localPaySum: 0, count: 0 },
      acknowledged: { followersSum: 0, avgEngagementSum: 0, localPaySum: 0, count: 0 }
    }
  );

  const maxBudget = campaign?.max_budget || 0;
  const currency = campaign?.currency || "";
  const targetReach = campaign?.target_reach || 0;
  const targetParticipants = campaign?.target_participants || 0;
  const postsCount = campaign?.number_of_posts || 0;
  const invoicesCount = campaign?.client_invoice_amount || 0;

  const expensesSum = campaign?.campaign_expenses_aggregate.aggregate?.sum?.amount || 0;
  const usedBudget =
    expensesSum + (users?.acknowledged.localPaySum || 0) / (campaign?.exchange_rate || 1);
  const acknowledgedUsersReach = (users?.acknowledged.followersSum || 0) * postsCount;
  const allUsersReach = (users?.all.followersSum || 0) * postsCount;

  const estimatedMarginFactor =
    Math.min(
      (users?.acknowledged.count || 0) / targetParticipants,
      allUsersReach / targetReach,
      1
    ) * invoicesCount;
  const cards = [
    {
      title: "Target margin",
      value:
        prettifyNumber(
          1 - maxBudget / (targetReach / 1000) / (invoicesCount / (targetReach / 1000)),
          { percentages: true }
        ) || "…"
    },
    campaign?.archived && {
      title: "Final margin",
      value: prettifyNumber(1 - usedBudget / invoicesCount, { percentages: true }) || "…"
    },
    !campaign?.archived && {
      title: "Estimated margin",
      value:
        (estimatedMarginFactor
          ? prettifyNumber(1 - usedBudget / estimatedMarginFactor, { percentages: true })
          : null) || "…"
    },
    !campaign?.archived && {
      title: "Current margin",
      value:
        prettifyNumber((invoicesCount - usedBudget) / invoicesCount, {
          percentages: true
        }) || "…"
    },
    {
      title: "Expenses",
      tooltip: "not invoiceable to the client",
      value: prettifyNumber(expensesSum) || "…"
    },
    {
      title: "Client CPM",
      value: `${
        prettifyNumber(invoicesCount / (targetReach / 1000), {
          rounded: true
        }) || "…"
      } ${currency}`
    },
    {
      title: "Client eCPM",
      value:
        invoicesCount && acknowledgedUsersReach
          ? `${prettifyNumber(invoicesCount / (acknowledgedUsersReach / 1000), {
              rounded: true
            })} ${currency}`
          : "…"
    }
  ];

  const rows = [
    {
      key: "payout_cpm",
      id: 0,
      name: "Payout CPM",
      target: `${prettifyNumber(maxBudget / (targetReach / 1000), {
        rounded: true
      })} ${currency}`,
      everyone: `${prettifyNumber(
        (users?.all.localPaySum || 0) / (campaign?.exchange_rate || 1) / (allUsersReach / 1000),
        {
          rounded: true
        }
      )} ${currency}`,
      participants: `${prettifyNumber(usedBudget / (acknowledgedUsersReach / 1000), {
        rounded: true
      })} ${currency}`
    },
    {
      key: "avg_payout",
      id: 1,
      name: "Average payout",
      target: `${prettifyNumber(maxBudget / targetParticipants, {
        rounded: true
      })} ${currency}`,
      everyone: `${prettifyNumber(
        (users?.all.localPaySum || 0) / (campaign?.exchange_rate || 1) / (users?.all.count || 0),
        {
          rounded: true
        }
      )} ${currency}`,
      participants: `${prettifyNumber(
        (users?.acknowledged.localPaySum || 0) /
          (campaign?.exchange_rate || 1) /
          (users?.acknowledged.count || 0),
        {
          rounded: true
        }
      )} ${currency}`
    },
    {
      key: "avg_reach",
      id: 2,
      name:
        campaign?.platform === "youtube"
          ? "Average 30d views per user"
          : "Average following per user",
      target: prettifyNumber(targetReach / (targetParticipants * postsCount), {
        rounded: true
      }),
      everyone: prettifyNumber(allUsersReach / ((users?.all.count || 0) * postsCount), {
        rounded: true
      }),
      participants: prettifyNumber(
        acknowledgedUsersReach / ((users?.acknowledged.count || 0) * postsCount),
        {
          rounded: true
        }
      )
    },
    {
      key: "avg_eng",
      name: `Average engagement (${campaign?.platform === "tiktok" ? "HA" : "int."})`,
      id: 3,
      target: "-",
      everyone: prettifyNumber((users?.all.avgEngagementSum || 0) / (users?.all.count || 0), {
        percentages: true
      }),
      participants: prettifyNumber(
        (users?.acknowledged.avgEngagementSum || 0) / (users?.acknowledged.count || 0),
        {
          percentages: true
        }
      )
    }
  ];

  const ytMostFrequentCountries = (() => {
    const ytUsers =
      data?.users_in_lists.map(({ campaign_user, user_youtube }) => ({
        ...user_youtube,
        state: campaign_user?.state
      })) || [];
    const demographicsStats = ytUsers.reduce<{
      all: Record<string, number>;
      acknowledged: Record<string, number>;
      allCount: number;
      acknowledgedCount: number;
      totalClicks: number;
    }>(
      (acc, yt) => {
        if (!yt.bitly_clicks_per_country) return acc;

        const isAcknowledged = ["confirmed", "approved"].includes(yt.state || "");
        acc.allCount += 1;
        if (isAcknowledged) acc.acknowledgedCount += 1;
        Object.keys(yt.bitly_clicks_per_country).forEach(country => {
          const { clicks } = yt.bitly_clicks_per_country[country];
          if (!acc.all[country]) acc.all[country] = 0;

          acc.totalClicks += clicks;
          acc.all[country] += clicks;

          if (isAcknowledged) {
            if (!acc.acknowledged[country]) acc.all[country] = 0;
            acc.acknowledged[country] += clicks;
          }
        });

        return acc;
      },
      { all: {}, allCount: 0, acknowledged: {}, acknowledgedCount: 0, totalClicks: 0 }
    );

    const allCountrySums = Object.keys(demographicsStats.all).map(key => ({
      value: demographicsStats.all[key],
      label: key
    }));
    const greatestAllCountriesIndexes = findGreatestElementIndexes(
      allCountrySums.map(i => i.value),
      3
    );

    const acknowledgedCountrySums = Object.keys(demographicsStats.all).map(key => ({
      value: demographicsStats.all[key],
      label: key
    }));
    const greatestAcknowledgedCountriesIndexes = findGreatestElementIndexes(
      acknowledgedCountrySums.map(i => i.value),
      3
    );

    return {
      all: greatestAllCountriesIndexes.map(index => allCountrySums[index]),
      acknowledged: greatestAcknowledgedCountriesIndexes.map(
        index => acknowledgedCountrySums[index]
      ),
      totalClicks: demographicsStats.totalClicks
    };
  })();

  return (
    <Root>
      <ProgressBarsGrid>
        <LinearProgress
          label={campaign?.platform === "youtube" ? "30d views" : "Reach"}
          value={(users?.acknowledged.followersSum || 0) * postsCount}
          max={targetReach}
        />
        <LinearProgress
          label="Participants"
          value={users?.acknowledged.count}
          max={campaign?.target_participants}
        />
        <LinearProgress label={`Budget ${currency}`} value={usedBudget} max={maxBudget} />
      </ProgressBarsGrid>

      <Divider />

      <StatsContainer>
        <Table
          tableId="campaign-participant-quick-stats"
          rows={rows}
          columns={[
            {
              headerName: "Name",
              field: "name",
              renderCell: ({ value }) => (
                <Typography fontSize={13} fontWeight={600}>
                  {value}
                </Typography>
              )
            },
            { headerName: "Target", field: "target", minWidth: 120 },
            { headerName: "Everyone", field: "everyone", minWidth: 120 },
            { headerName: "Participants", field: "participants", minWidth: 120 }
          ]}
        />

        <Section elevation={10}>
          {cards.map(
            card =>
              card && (
                <Tooltip key={card.title} title={card.tooltip || ""}>
                  <Card>
                    <CardHeader subheader={card.title} />
                    <SCardContent>{card.value}</SCardContent>
                  </Card>
                </Tooltip>
              )
          )}
        </Section>

        {campaign?.platform === "youtube" && (
          <>
            <Section>
              {ytMostFrequentCountries.all.map(country => (
                <Card key={country.label}>
                  <CardHeader
                    title={youtubeCountries[country.label]}
                    subheader="Demographics (everyone)"
                  />
                  <SCardContent>
                    <CircularProgress
                      value={(country.value / ytMostFrequentCountries.totalClicks) * 100}
                    />
                  </SCardContent>
                </Card>
              ))}
            </Section>

            <Section>
              {ytMostFrequentCountries.acknowledged.map(country => (
                <Card key={country.label}>
                  <CardHeader
                    title={youtubeCountries[country.label]}
                    subheader="Demographics (acknowledged)"
                  />
                  <SCardContent>
                    <CircularProgress
                      value={(country.value / ytMostFrequentCountries.totalClicks) * 100}
                    />
                  </SCardContent>
                </Card>
              ))}
            </Section>
          </>
        )}
      </StatsContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProgressBarsGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto auto;
`;

const StatsContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;

const Section = styled(Paper)`
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: auto auto auto;
  > div {
    flex-grow: 1;
  }
`;

const SCardContent = styled(CardContent)`
  padding-top: 0 !important;
  font-size: 20px;
`;
