import type { FC } from "react";

import { CircularProgress, Tooltip } from "@mui/material";
import { prettifyDate, prettifyNumber } from "@relatable/helpers";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { UpdateStats } from "components/UpdateStats";
import { Center } from "lib/styled";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { DeleteVideoButton } from "./DeleteVideoButton";
import { NoteCell } from "./NoteCell";
import {
  CampaignVideoStatsDocument,
  useCampaignVideoStatsQuery,
  useUpdateCampaignVideoStatsMutation
} from "./generated";

export const VideosTable: FC = () => {
  const snackbar = useSnackbar();
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data: currentData, previousData } = useCampaignVideoStatsQuery({
    variables: { stubs: campaignStubs }
  });
  const data = previousData || currentData;
  const [updateStats] = useUpdateCampaignVideoStatsMutation({
    refetchQueries: [CampaignVideoStatsDocument],
    awaitRefetchQueries: true,
    onError(error) {
      snackbar.error("Sth went wrong when updating a video");
      snackbar.error(error.message);
      console.error(error);
    },
    onCompleted() {
      snackbar.success("Video successfully updated");
    }
  });

  const rltToKeys = (
    data?.campaigns?.flatMap(c => c.campaign_users.flatMap(u => u.campaign_videos)) ?? []
  )
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  if (!data || linksLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const rows = data.campaigns.flatMap(campaign =>
    campaign.campaign_users.flatMap(campaignUser =>
      campaignUser.campaign_videos.map(video => {
        const stats = video.campaign_video_stat;

        const bitlyClicks = stats?.link_clicks ?? 0;
        const rltClicks = linkData?.link_clicks.find(
          lc => lc.key === extractRltToKey(video.track_url)
        )?.clicks;
        const clicks = bitlyClicks + (rltClicks ?? 0);

        const interactions =
          (stats?.like_count ?? 0) +
          (stats?.dislike_count ?? 0) +
          (stats?.comment_count ?? 0) +
          (stats?.favorite_count ?? 0);

        return {
          key: video.id,

          videoLink: `https://www.youtube.com/watch?v=${video.video_id}`,
          campaign_video_id: video.id,
          user_id: campaignUser.user?.id,
          channel_id: campaignUser.user?.user_youtube?.channel_id,
          channel_title: campaignUser.user?.user_youtube?.channel_title,
          median_views: campaignUser.user?.user_youtube?.median_views,
          subscribers: campaignUser.user?.user_youtube?.subscribers,
          video_id: video.video_id,
          title: video.title,
          notes: video.notes,
          published_at: video.published_at,
          views: stats?.view_count,
          likes: stats?.like_count ?? 0,
          comments: stats?.comment_count ?? 0,
          dislikes: stats?.dislike_count ?? 0,
          favorites: stats?.favorite_count ?? 0,
          clicks,
          engagement: stats?.view_count ? interactions / (stats?.view_count ?? 0) : 0,
          organic_engagement: campaignUser.user?.hypeauditor?.avg_engagement,
          ctr: stats?.view_count ? clicks / (stats?.view_count ?? 0) : 0,
          thumbnail: video.thumbnail,
          track_url: video.track_url
        };
      })
    )
  );

  const columns: TableColumn<typeof rows>[] = [
    {
      headerName: "Channel",
      field: "channel_title",
      renderCell: ({ row, value }) => <Link to={`/user/${row.user_id}`}>{value}</Link>
    },
    {
      headerName: "Prod note",
      field: "notes",
      renderCell: ({ value, row }) => <NoteCell note={value} campaignVideoId={row.key} />
    },
    {
      headerName: "Median views",
      field: "median_views",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Subscribers",
      field: "subscribers",
      renderCell: ({ value }) => prettifyNumber(typeof value === "string" ? Number(value) : value)
    },
    {
      headerName: "Video",
      field: "videoLink",
      renderCell: ({ row, value }) => (
        <Tooltip title={<img src={row.thumbnail || ""} width="300" alt="video thumbnail" />}>
          <Link to={value} target="_blank" rel="noreferrer">
            {row.title}
          </Link>
        </Tooltip>
      )
    },
    {
      headerName: "Published",
      field: "published_at",
      renderCell: ({ value }) => prettifyDate(value)
    },
    {
      field: "key",
      headerName: "Statistics",
      minWidth: 75,
      renderCell: ({ value }) => (
        <UpdateStats onUpdate={() => updateStats({ variables: { id: value } })} />
      )
    },
    {
      headerName: "Views",
      field: "views",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Likes",
      field: "likes",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Comments",
      field: "comments",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Dislikes",
      field: "dislikes",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Engagement",
      field: "engagement",
      renderCell: ({ value }) => prettifyNumber(value, { percentages: true })
    },
    {
      headerName: "Organic engagement",
      field: "organic_engagement",
      renderCell: ({ value }) => prettifyNumber(value, { percentages: true })
    },
    {
      headerName: "Clicks",
      field: "clicks",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "CTR",
      field: "ctr",
      renderCell: ({ value }) => prettifyNumber(value, { percentages: true })
    },
    {
      field: "campaign_video_id",
      headerName: "Delete",
      renderCell: ({ value, row }) => <DeleteVideoButton title={row.title || ""} id={value} />
    }
  ];

  return (
    <Table
      tableId="campaign-videos"
      rows={rows}
      columns={columns}
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
    />
  );
};
